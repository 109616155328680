export const filterByTitle = (array, institutionCode) => {
  // 
  const titlesFiltered = () => {
    switch (institutionCode) {
      case 'SFIT':
        return ['Electronic Journals - MDPI', 'Science Alert Journals', 'FDA'];
      case 'CMVC':
        return ['Inner Body', 'FDA', 'RedCrossBlood'];
      case 'CBT':
        return ['Libretexts Mathematics', 'SmallNetBuilder', 'Dictionary.com'];
      case 'MOORE':
        return ['World Wildlife Fund', 'Reviews in Fish Biology and Fisheries', 'National Geographic Society'];
      case 'UCC':
        return ['Libretexts Mathematics', 'SmallNetBuilder', 'Dictionary.com'];
      case 'ABC':
        return ['Libretexts Mathematics', 'SmallNetBuilder', 'Dictionary.com'];
      case 'SABER':
        return ['Inner Body', 'FDA', 'RedCrossBlood'];
      case 'HCAS':
        return ['Inner Body', 'FDA', 'RedCrossBlood'];
      case 'CUF':
        return ['Libretexts Mathematics', 'SmallNetBuilder', 'Dictionary.com'];
      case 'UNAD':
        return ['Libretexts Mathematics', 'SmallNetBuilder', 'Dictionary.com'];
      case 'BIU':
        return ['Libretexts Mathematics', 'SmallNetBuilder', 'Dictionary.com'];
      case 'UOF':
        return ['Libretexts Mathematics', 'SmallNetBuilder', 'Dictionary.com'];
      case 'DECO_LOVERS':
        return ['Color palette generator', 'The Casa Revista', 'El Mueble'];
      default:
        return ['FDA', 'RedCrossBlood', 'Inner Body'];
    }
  };
  return array.filter((item) => titlesFiltered().includes(item.title));
};
