import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  prompt: '',
  promptFetched: '',
  response: [],
  isLoading: false,
};

const iaSlice = createSlice({
  name: 'ia',
  initialState,
  reducers: {
    setPrompt: (state, action) => {
      state.prompt = action.payload;
    },
    setPromptFetched: (state, action) => {
      state.promptFetched = action.payload;
    },
    setResponse: (state, action) => {
      state.response = [...state.response, ...action.payload];

      // Si el tamaño del array supera 10, eliminar el primer elemento
      if (state.response.length > 10) {
        state.response.splice(0, state.response.length - 10);
      }
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setResetResponse: (state) => {
      state.prompt = '';
      state.promptFetched = '';
      state.response = [];
      state.isLoading = false;
    },
  },
});

export const {
  setPrompt,
  setPromptFetched,
  setResponse,
  setIsLoading,
  setResetResponse,
} = iaSlice.actions;

export default iaSlice.reducer;
