import React from 'react';
// Component
import ExampleInstruction from '../ExampleInstruction';
// Styles
import './Instructions.css';

const Instructions = ({ searchType }) => {
  const currentlyRoute = window.location.pathname.replace('/', '');

  const infoLibraryAdvanced = () => {
    return (
      <>
        <div className='instructionsContainer__video'>
          <p>
            Crafting effective search queries is crucial for information
            retrieval. Enhance precision by using search equations with logical
            operators, refining and tailoring queries to align with specific
            criteria.
          </p>
        </div>
        <div className='instructionsContainer__instructions'>
          <h3>
            <b>To refine your searches you can use:</b>
          </h3>
          <div>
            <p className='instructionsContainer__operator'>" "</p>
            <p className='instructionsContainer__description'>
              to specify exact matching
            </p>
            <ExampleInstruction message={['"Pediatric phlebotomy"']} />
          </div>

          <div>
            <p className='instructionsContainer__operator'>AND, OR, NOT</p>
            <p className='instructionsContainer__description'>
              to add logic to to your search
            </p>
            <ExampleInstruction
              message={[
                'climate change AND renewable energy',
                'artificial intelligence OR machine learning',
                'smartphones NOT Apple',
              ]}
            />
          </div>
          <div>
            <p className='instructionsContainer__operator'>( )</p>
            <p className='instructionsContainer__description'>
              to group your search terms
            </p>
            <ExampleInstruction
              message={[
                '(climate change AND renewable energy) OR (global warming AND sustainable energy)',
              ]}
            />
          </div>
        </div>
      </>
    );
  };

  const infoLibraryIA = () => {
    return (
      <>
        <div className='instructionsContainer__video'>
          <p>
            Crafting effective search queries is crucial for information
            retrieval. Enhance precision by using search equations with logical
            operators, refining and tailoring queries to align with specific
            criteria.
          </p>
        </div>
        <div className='instructionsContainer__instructionsIA'>
          <h3>
            <b>To refine your searches you can use:</b>
          </h3>
          <div className='instructionsContainer__instructionsText'>
            <p className='instructionsContainer__operator'>
              "Search for research articles on the effects of meditation on
              reducing blood pressure."
            </p>
            <p className='instructionsContainer__description'>
              This instruction is precise and specific. By saying "search for
              research articles," the user is requesting a concrete action: to
              find information. Finally, "on the effects of meditation on
              reducing blood pressure" specifies the topic.
            </p>
          </div>
        </div>
      </>
    );
  };

  if (
    currentlyRoute === 'articles' &&
    (searchType === 'advanced' || searchType === 'ia')
  ) {
    return (
      <section className='instructionsContainer'>
        {searchType === 'advanced' ? infoLibraryAdvanced() : infoLibraryIA()}
      </section>
    );
  }
};

export default Instructions;
