import React, { useState } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Components
import CardVideo from '../../Components/CardVideo';
import Container from '../../Components/Container';
import Pagination from '../../Components/Pagination';
// Hooks
import { useScrollToTop } from '../../hooks/useScrollToTop';
// Services
import { getVideosTutorials } from '../../services/getVideosTutorials';
// Syles
import './TutorialsPage.css';

const TutorialsPage = () => {
  // Hookks
  const { handleScrollToTop } = useScrollToTop();

  // Slices
  const { institutionCode } = useSelector((state) => state.institution);

  // Variables
  const [pageShowedTutorials, setPageShowedTutorials] = useState(1);

  const dataVideos = getVideosTutorials(institutionCode);

  // Pagination
  const itemsPerPage = 12;
  const totalNews = dataVideos.length;
  const indexOfLastItem = pageShowedTutorials * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = dataVideos.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(totalNews / itemsPerPage);

  // Funcion de cambio de paginacion
  const handlePageChange = (newPage) => {
    handleScrollToTop(0);
    if (newPage > pageShowedTutorials) {
      if (
        pageShowedTutorials < totalPages ||
        (Math.ceil(getVideosTutorials()?.length / itemsPerPage) > 1 &&
          totalPages === 0)
      ) {
        setPageShowedTutorials(newPage);
      }
    } else {
      if (pageShowedTutorials > 1) {
        setPageShowedTutorials(newPage);
      }
    }
  };

  return (
    <main className='Tutorials'>
      <Container>
        <section className='AmericanLifeContainer'>
          {currentItems?.map((video, i) => {
            return (
              <CardVideo
                key={i}
                image={video.image}
                time={video.duration}
                title={video.title}
                description={video.description}
                url={video.url}
                language={video.language}
                type={video.type}
              />
            );
          })}
        </section>
        <Pagination
          pageShowed={pageShowedTutorials}
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
      </Container>
    </main>
  );
};

export default TutorialsPage;
