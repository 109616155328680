import React from 'react';
// utils
import { formatThousandSeparator } from '../../utils/formatThousandSeparator';
// Icons
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
// Styles
import './Pagination.css';

const Pagination = ({ pageShowed, totalPages, handlePageChange }) => {
  const handleBack = () => {
    if (pageShowed !== 1) {
      handlePageChange(pageShowed - 1);
    }
  };
  const handleNext = () => {
    if (pageShowed !== totalPages) {
      handlePageChange(pageShowed + 1);
    }
  };

  if (totalPages > 1) {
    return (
      <section className='navigationPages'>
        <button
          className={
            pageShowed === 1
              ? 'navigationPagesButton left deactivateButton'
              : 'navigationPagesButton left'
          }
          onClick={handleBack}
        >
          <MdOutlineKeyboardArrowLeft className='arrowButtom' />
          Back
        </button>
        <div>
          <p>
            {pageShowed} of&nbsp;{` `}
          </p>
          {totalPages > 0 ? (
            <p> {formatThousandSeparator(totalPages)}</p>
          ) : (
            <span className='loaderArt'></span>
          )}
        </div>

        <button
          className={
            pageShowed === totalPages
              ? 'navigationPagesButton right deactivateButton'
              : 'navigationPagesButton right'
          }
          onClick={handleNext}
        >
          Next
          <MdOutlineKeyboardArrowRight className='arrowButtom' />
        </button>
      </section>
    );
  }
};

export default Pagination;
