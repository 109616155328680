import { useDispatch, useSelector } from 'react-redux';
import { setSocketGallery } from '../app/features/metasearchSlice';
import {
  setArt,
  setSources,
  setTotalArt,
  setIsLoadingArt,
  setIsLoadingMoreData,
  setPeriodSelected,
  setSelectedStartYear,
  setSelectedEndYear,
  setColors,
  setSelectedColor,
  resetArt,
  resetFilters,
  // Filters
  setPeriodFetched,
  setSelectedSourcesArt,
  setSourcesFetchedArt,
  setColorsFetched,
  setStartYearFetched,
  setEndYearFetched,
  setPageFetched,
  setQueryFetched,
} from '../app/features/artSlice';

export const useGallery = () => {
  const dispatch = useDispatch();
  const { institutionCode } = useSelector((state) => state.institution);
  const metaSearchStore = useSelector((state) => state.metasearch);
  const low_priority_list = ['NAGUS', 'HARVARDMUSEUM'];

  const get_low_priority = (list1, list2) => {
    return list1.filter((element) => list2.includes(element));
  };

  const getGalleryDataSocket = async (
    query = '',
    page = 1,
    startYear = '',
    endYear = '',
    sources = [],
    color = '',
    only_images = false,
    only_videos = false,
    only_audio = false,
    period = ''
  ) => {
    // Controlador de conteo en respuesta Single del Websocket
    let totalArtSingle = 0;
    // Guardando informacion
    if (page === 1) {
      dispatch(resetArt());
      dispatch(resetFilters());
      dispatch(setIsLoadingArt(true));
      dispatch(setIsLoadingMoreData(true));
      dispatch(setQueryFetched(query));
      dispatch(setSelectedSourcesArt(sources));
      dispatch(setSourcesFetchedArt(sources));
      dispatch(setPeriodSelected(period));
      dispatch(setPeriodFetched(period));
      dispatch(setSelectedColor(color));
      dispatch(setColorsFetched(color));
      dispatch(setStartYearFetched(startYear));
      dispatch(setEndYearFetched(endYear));
      dispatch(setSelectedStartYear(startYear));
      dispatch(setSelectedEndYear(endYear));
    }
    dispatch(setPageFetched(page));
    // Verifica si hay una conexión anterior y la cierra
    if (metaSearchStore && metaSearchStore.socketGallery instanceof WebSocket) {
      metaSearchStore.socketGallery.close();
    }

    // Se crea la configuración para la conexión al web socket
    const websocketArt = new WebSocket(
      // `ws://localhost:8000/search?auth_token=${localStorage.token}&version=v2`
      `wss://olib-api.aquinasnetwork.com/search?auth_token=${localStorage.token}&version=v2`
    );

    // Se crea el nuevo estado para la variable "socket" que verifica si hay o no una conexión previa
    dispatch(setSocketGallery(websocketArt));

    // Envia la petición al web socket para iniciar la conexión
    websocketArt.onopen = function () {
      const socketQuery = {
        query_token: `Bearer ${localStorage.token}`,
        query,
        params: {
          sources,
          low_priority: get_low_priority(sources, low_priority_list),
          color,
          start_year: startYear,
          end_year: endYear,
          only_images,
          only_videos,
          only_audio,
        },
        institution_code: institutionCode,
        cache: false,
        source_type: 'gallery',
        page,
        version: 2,
      };
      websocketArt.send(JSON.stringify(socketQuery));

      // Recibe la información del websocketBooks
      websocketArt.onmessage = function (event) {
        const dataSource = JSON.parse(event.data);

        if (dataSource.type === 'summary') {
          dispatch(setTotalArt(dataSource?.count));
          page === 1 && dispatch(setColors(dataSource.colors));
          dispatch(setIsLoadingMoreData(false));
          dispatch(setIsLoadingArt(false));
          websocketArt.close();
        }

        if (dataSource.type === 'single') {
          dispatch(setArt([...dataSource.data.results]));
          const source_info = {
            name: dataSource.data.name_source,
            code: dataSource.data.code_source,
            count: dataSource.data.count,
          };
          totalArtSingle = totalArtSingle + dataSource.data.count;
          dispatch(setTotalArt(totalArtSingle));
          dispatch(setSources(source_info));
          dispatch(setIsLoadingArt(false));
        }
      };

      // Cierre de la conexión por tiempo
      setTimeout(() => {
        dispatch(setIsLoadingArt(false));
        dispatch(setIsLoadingMoreData(false));
        websocketArt.close();
      }, 120000);
    };
  };

  return { getGalleryDataSocket };
};
