import React, { useState } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Components
import Modal from 'react-modal';
// Services
import { sendUserSearchInformation } from '../../services/sendUserSearchInformation';
// Images
import PlayButtonImage from '../../Images/play-button.svg';
import PlaceholderImage from '../../Images/placeholder-image.png';
// Styles
import './CardVideo.css';

const CardVideo = ({
  image,
  time,
  title,
  description,
  url,
  language,
  type = 'youtube',
}) => {
  const { user_email } = useSelector((state) => state.user);
  const { institutionCode } = useSelector((state) => state.institution);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState('');

  const getURL = (id, type) => {
    switch (type) {
      case 'youtube':
        return `https://www.youtube.com/embed/${id}`;
      case 'vimeo':
        return `https://player.vimeo.com/video/${id}`;
      default:
        return `https://www.youtube.com/embed/${id}`;
    }
  };

  const handleClicCard = (id, type) => {
    setSelectedVideoUrl(`${getURL(id, type)}`);
    setModalIsOpen(true);
    sendUserSearchInformation(
      title,
      'Google scholar',
      url,
      'Media',
      user_email,
      institutionCode
    );
  };

  // Manejador de modal (close)
  const closeModal = () => {
    setSelectedVideoUrl('');
    setModalIsOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel='Video Modal'
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            maxWidth: '80%',
            maxHeight: '80%',
          },
        }}
      >
        <iframe
          className='videoModal'
          title='title video'
          src={selectedVideoUrl}
          controls
          allowFullScreen
        ></iframe>
      </Modal>
      <div
        className='card-video'
        onClick={() => handleClicCard(url, type)}
        role='button'
        tabIndex='0'
      >
        <div className='thumbnail_image'>
          <div className='thumbnail_image__play-button'>
            <img src={PlayButtonImage} alt='play' className='button-svg' />
          </div>
          <div className='thumbnailContainer'>
            <img
              src={image ? image : PlaceholderImage}
              alt='thumbnail'
              className='thumbnail'
            />
          </div>

          {time && <div className='thumbnail_time'>{time}</div>}
        </div>

        <div className='resource_content'>
          {title && <div className='resource_title'>{title}</div>}
          {description && (
            <div className='resource_description'>{description}</div>
          )}
        </div>
      </div>
    </>
  );
};

export default CardVideo;
