import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  jobs: [],
  queryJobs: '',
  isLoadingJobs: false,
  isLoadingMoreJobs: false,
  moreDataAvailable: true,
  pageJobs: 1,
  queryJobsFetched: '',
  applyJob: [],
  jobFetched: {},
  viewApplyJobs: false,
  remoteJob: 0,
  next_page: '',
};

const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    setJobs: (state, action) => {
      state.jobs = [...state.jobs, ...action.payload];
    },
    setQueryJobs: (state, action) => {
      state.queryJobs = action.payload;
    },
    setQueryJobsFetched: (state, action) => {
      state.queryJobsFetched = action.payload;
    },
    setIsLoadingJobs: (state, action) => {
      state.isLoadingJobs = action.payload;
    },
    setIsLoadingMoreJobs: (state, action) => {
      state.isLoadingMoreJobs = action.payload;
    },
    setMoreDataAvailable: (state, action) => {
      state.moreDataAvailable = action.payload;
    },
    setPageJobs: (state, action) => {
      state.pageJobs = action.payload;
    },
    setApplyJob: (state, action) => {
      state.applyJob = action.payload;
    },
    setViewApplyJobs: (state, action) => {
      state.viewApplyJobs = action.payload;
    },
    setJobFetched: (state, action) => {
      state.jobFetched = action.payload;
    },
    setRemoteJob: (state, action) => {
      state.remoteJob = action.payload;
    },
    setNextPage: (state, action) => {
      state.next_page = action.payload;
    },
    searchNewData: (state) => {
      state.jobs = [];
      state.moreDataAvailable = true;
      state.pageJobs = 1;
      state.jobFetched = {};
      state.applyJob = [];
    },
    resetJobs: (state) => {
      state.jobs = [];
      state.isLoadingJobs = false;
      state.moreDataAvailable = true;
      state.pageJobs = 1;
      state.queryJobs = '';
      state.queryJobsFetched = '';
      state.jobFetched = {};
      state.applyJob = [];
      state.next_page = '';
    },
  },
});

export const {
  setJobs,
  setQueryJobs,
  setQueryJobsFetched,
  setIsLoadingJobs,
  setIsLoadingMoreJobs,
  setMoreDataAvailable,
  setPageJobs,
  setApplyJob,
  setViewApplyJobs,
  setJobFetched,
  searchNewData,
  setNextPage,
  resetJobs,
  setRemoteJob,
} = jobsSlice.actions;

export default jobsSlice.reducer;
