import { SkeletonTypes } from './types';

import './styles.css';

// Switch with all types of skeleton
const renderSkeletonByType = (type) => {
	switch (type) {
		case SkeletonTypes.FILTER:
			return (
				<div className='blog-post o-media'>
					<div className='o-media__body'>
						<div className='o-vertical-spacing'>
							<h3 className='blog-post__headline'>
								<span className='skeleton-box' style={{ width: '55%' }} />
							</h3>
							<p>
								<span className='skeleton-box' style={{ width: '80%' }} />
								<span className='skeleton-box' style={{ width: '90%' }} />
								<span className='skeleton-box' style={{ width: '83%' }} />
								<span className='skeleton-box' style={{ width: '80%' }} />
							</p>
							<div className='blog-post__meta'>
								<span className='skeleton-box' style={{ width: 70 }} />
							</div>
						</div>
					</div>
				</div>
			);
		case SkeletonTypes.MEDIA_RESOURCE_CARD:
			return (
				<div className='media-resource-skeleton-card'>
					<div className='blog-post o-media'>
						<div className='o-media__body'>
							<div className='o-vertical-spacing'>
								<h3 className='blog-post__headline'>
									<span className='skeleton-box' />
								</h3>
								<div className='skeleton-body'>
									<span className='skeleton-box' style={{ width: '80%' }} />
									<span className='skeleton-box' style={{ width: '90%' }} />
									<span className='skeleton-box' style={{ width: '83%' }} />
								</div>
								<div className='blog-post__meta'>
									<span className='skeleton-box' style={{ width: 70 }} />
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		case SkeletonTypes.NEWS_CARD:
			return (
				<div className='news-skeleton-card'>
					<div className='blog-post o-media'>
						<div className='o-media__body'>
							<div className='o-vertical-spacing'>
								<h3 className='blog-post__headline'>
									<span className='skeleton-box' />
								</h3>
								<div className='skeleton-body'>
									<span
										className='skeleton-box skeleton-body__title'
										style={{ width: '80%' }}
									/>
									<span className='skeleton-box' style={{ width: '90%' }} />
									<span className='skeleton-box' style={{ width: '83%' }} />
									<span
										className='skeleton-box skeleton-body__date'
										style={{ width: '50%' }}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		case SkeletonTypes.LIBRARY_CARD:
		default:
			return (
				<div className='containerCard'>
					<div className='blog-post o-media'>
						<div className='o-media__body'>
							<div className='o-vertical-spacing'>
								<h3 className='blog-post__headline'>
									<span className='skeleton-box' style={{ width: '55%' }} />
								</h3>
								<p>
									<span className='skeleton-box' style={{ width: '80%' }} />
									<span className='skeleton-box' style={{ width: '90%' }} />
									<span className='skeleton-box' style={{ width: '83%' }} />
									<span className='skeleton-box' style={{ width: '80%' }} />
								</p>
								<div className='blog-post__meta'>
									<span className='skeleton-box' style={{ width: 70 }} />
								</div>
							</div>
						</div>
					</div>
				</div>
			);
	}
};

export const LoadingSkeleton = ({ type }) => {
	return <>{renderSkeletonByType(type)}</>;
};
