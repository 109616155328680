import { fetchWithoutToken } from '../utils/fetch';

export const getInterestingLinks = async (institutionCode) => {
  try {
    const res = await fetchWithoutToken.get(
      `/resources?institution_code=${institutionCode}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );

    return res;
  } catch (error) {
    console.error(error);
  }
};
