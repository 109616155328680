import React from 'react';
// Redux
import { useSelector } from 'react-redux';
// Services
import { sendUserSearchInformation } from '../../services/sendUserSearchInformation';
// Utils
import { publicationDate } from '../../utils/publicationDate';
// Styles
import './CardNews.css';

const CardNews = ({
  title,
  url,
  image_url,
  resource_origin,
  publication_date,
  description,
  index = 0,
}) => {
  const { user_email } = useSelector((state) => state.user);
  const { institutionCode } = useSelector((state) => state.institution);

  const handleClicCard = () => {
    sendUserSearchInformation(
      title,
      resource_origin,
      url,
      'news',
      user_email,
      institutionCode
    );
    window.open(url, '_blank');
  };

  return (
    <div className={index === 1 ? 'cardNews big' : 'cardNews'}>
      <div className='containerImageNews'>
        <img
          className='cardNews__image'
          src={image_url}
          loading='lazy'
          alt={title}
        />
      </div>

      <div className='cardNewsContainer'>
        <div className='cardNewsSearchedContainer'>
          <div className='info'>
            <p className='sourceNewsSearched'>{resource_origin}</p>
            {publication_date && (
              <p className='publicationDateNewsSearched'>
                {publicationDate(publication_date)}
              </p>
            )}
          </div>
        </div>
        <h4 className='cardNews__title'>{title}</h4>
        {description && <p className='cardNews__text'>{description}</p>}
        <button className='showMore' onClick={handleClicCard}>
          Show More
        </button>
      </div>
    </div>
  );
};

export default CardNews;
