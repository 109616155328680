import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  news: [], // Contiene todas las noticias (Cuando se busca por query)
  is_news_loading: false,
  isNewsDataFetched: false,
  total_news_query: 0, // Contiene el total de noticias buscadas por query
  base_news: [], // Noticias que estan en home o que aparecen cuando no hay busqueda
  pageFetched: 1,
  pageShowed: 1,
  queryNewsView: false,
};

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setNews: (state, action) => {
      state.news = action.payload;
    },
    setNewsPagination: (state, action) => {
      state.newsPagination = action.payload;
    },
    setTotalNewsQuery: (state, action) => {
      state.total_news_query = action.payload;
    },
    setIsNewsDataFetched: (state, action) => {
      state.isNewsDataFetched = action.payload;
    },
    setIsNewsLoading: (state, action) => {
      state.is_news_loading = action.payload;
    },
    setResetNewsQuery: (state) => {
      state.is_news_loading = false;
      state.isNewsDataFetched = false;
      state.news = [];
      state.pageFetched = 1;
      state.pageShowed = 1;
      state.total_news_query = 0;
    },
    setBaseNews: (state, action) => {
      state.base_news = [...state.base_news, ...action.payload];
    },
    setResetBaseNews: (state) => {
      state.base_news = [];
    },
    setPageFetched: (state, action) => {
      state.pageFetched = action.payload;
    },
    setPageShowed: (state, action) => {
      state.pageShowed = action.payload;
    },
    setQueryNewsView: (state, action) => {
      state.queryNewsView = action.payload;
    },
  },
});

export const {
  setNews,
  setNewsPagination,
  setIsNewsDataFetched,
  setBaseNews,
  setIsNewsLoading,
  setTotalNewsQuery,
  setResetNewsQuery,
  setResetBaseNews,
  setPageFetched,
  setPageShowed,
  setQueryNewsView,
} = newsSlice.actions;

export default newsSlice.reducer;
