import React, { useRef } from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Slices
import {
  setPrompt,
  setPromptFetched,
  setResponse,
  setIsLoading,
} from '../../app/features/iaSlice';
// Icons
import { GrFormNextLink } from 'react-icons/gr';
// Services
import { getIASearch } from '../../services/getIASearch';
import { sendUserInformation } from '../../services/sendUserInformation';
import { sendUserSearchInformation } from '../../services/sendUserSearchInformation';
// Styles
import './IASearchBar.css';
// Utils
import { centerTo } from '../../utils/centerTo.js';

const IASearchBar = ({ setSearchBarHeight }) => {
  const dispatch = useDispatch();
  const textareaRef = useRef(null);

  // Slices
  const { user_email } = useSelector((state) => state.user);
  const { institutionCode } = useSelector((state) => state.institution);
  const { prompt, response } = useSelector((state) => state.ia);

  const autoResize = () => {
    const textarea = textareaRef.current;
    textarea.style.height = 'auto'; // Restablece la altura
    textarea.style.height = `${textarea.scrollHeight}px`; // Ajusta la altura
    setSearchBarHeight(textarea.scrollHeight);
  };

  const handleSendInformation = () => {
    // Send Search information
    sendUserSearchInformation(
      prompt,
      'Aquino IA',
      'IA',
      'Aquino IA',
      user_email,
      institutionCode
    );
    // Send use information
    response.length === 0 &&
      sendUserInformation(
        'Aquino IA',
        'Aquino IA',
        user_email,
        institutionCode
      );
  };

  const iaSearchAction = () => {
    if (prompt.length > 0) {
      dispatch(setIsLoading(true));
      dispatch(setPromptFetched(prompt));
      centerTo('loading');

      // Handle Send Informacion Search and Use
      handleSendInformation();

      // Function to search in Aquino IA
      getIASearch(prompt)
        .then((res) => {
          if (res.status === 200) {
            dispatch(setPrompt(''));
            dispatch(setResponse([res.data]));
            dispatch(setIsLoading(false));
            centerTo('answer', response.length);
          }
        })
        .catch((error) => {
          console.error(error);
          dispatch(setResponse([]));
          dispatch(setIsLoading(false));
        });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && prompt.length > 0) {
      if (!e.shiftKey && !e.ctrlKey) {
        e.preventDefault();
        iaSearchAction();
      }
    }
  };

  return (
    <div className='iaSearch'>
      <textarea
        ref={textareaRef}
        className='iaSearchBar'
        type='text'
        placeholder='Type your search term here'
        onInput={autoResize}
        rows={1}
        value={prompt}
        onChange={(e) => dispatch(setPrompt(e.target.value))}
        onKeyDown={handleKeyDown}
      ></textarea>
      <button
        className={
          prompt.length > 0 ? 'iaSearchButton withText' : 'iaSearchButton'
        }
        onClick={iaSearchAction}
      >
        <GrFormNextLink />
      </button>
    </div>
  );
};

export default IASearchBar;
