import { getAddLibraryData } from '../services/getAddLibraryData';

export const addLibraryData = (data, queryFetched, institutionCode) => {
  const dataToAdd = getAddLibraryData(institutionCode);

  if (dataToAdd.length > 0) {
    let dataAdded = data;
    dataToAdd?.map((element) => {
      if (element.words.includes(queryFetched)) {
        dataAdded = [element, ...dataAdded];
      }
      return null;
    });
    return dataAdded;
  }
  return data;
};
