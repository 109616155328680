import { fetchWithoutToken } from '../utils/fetch';

// Función para obtener la IP del usuario
export const getIpUser = async () => {
  const dummyIP = '255.255.255.255';

  try {
    const response = await fetchWithoutToken.get(
      'https://api.ipify.org?format=json'
    );
    return response.data.ip || dummyIP;
  } catch (error) {
    console.error('Error obteniendo la IP del usuario:', error);
    return dummyIP;
  }
};
