import { fetchWithoutToken } from '../utils/fetch';

// Este endpoint se usa para la sección de Jobs
export const getMedicalDictionary = async (word) => {
	try {
		const res = await fetchWithoutToken.get(
			`https://dictionaryapi.com/api/v3/references/medical/json/${word}?key=${process.env.REACT_APP_MEDICAL_DICTIONARY_API}`
		);
		return res;
	} catch (error) {
		console.error(error);
	}
};

export const getElementaryDictionary = async (word) => {
	try {
		const res = await fetchWithoutToken.get(
			`https://dictionaryapi.com/api/v3/references/sd2/json/${word}?key=${process.env.REACT_APP_ELEMENTARY_DICTIONARY_API}`
		);
		return res;
	} catch (error) {
		console.error(error);
	}
};

export const getThesaurusDictionary = async (word) => {
	try {
		const res = await fetchWithoutToken.get(
			`https://dictionaryapi.com/api/v3/references/thesaurus/json/${word}?key=${process.env.REACT_APP_THESAURUS_DICTIONARY_API}`
		);
		return res;
	} catch (error) {
		console.error(error);
	}
};
