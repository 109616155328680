import { fetchWithoutToken } from '../utils/fetch';

// Este endpoint se usa para la busqueda de noticias a traves de un termino (query)
export const getMultimedia = async (search = '', page = 1) => {
	try {
		const res = await fetchWithoutToken.get(
			`/resources/videos/search?query=${search}&page=${page}`,
			{
				headers: {
					Authorization: `Bearer ${localStorage.token}`,
				},
			}
		);

		return res;
	} catch (error) {
		console.error(error);
	}
};
