import React from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Icons
import { IoMdSearch } from 'react-icons/io';
// Slices
import {
  setJobs,
  setQueryJobs,
  setIsLoadingJobs,
  setIsLoadingMoreJobs,
  searchNewData,
  setMoreDataAvailable,
  setPageJobs,
  setQueryJobsFetched,
  setRemoteJob,
  setNextPage,
} from '../../app/features/jobsSlice';
// Services
import { getJobs } from '../../services/getJobs';
// Styles
import './JobsSearchBar.css';

const JobsSearchBar = () => {
  const dispatch = useDispatch();
  const { queryJobs } = useSelector((state) => state.jobs);

  const searchData = (query, page) => {
    if (query?.length > 0) {
      dispatch(setIsLoadingJobs(true));
      dispatch(searchNewData());
      dispatch(setRemoteJob(0));
      getJobs(query, page, 0)
        .then((response) => {
          if (response.status === 200) {
            
            if (response?.data?.data && Array.isArray(response.data.data)) {
              dispatch(setJobs(response.data.data));
              dispatch(setNextPage(response.data.next_page));
              dispatch(setQueryJobsFetched(query));
              dispatch(setPageJobs(page));
              if (response?.data?.data?.length < 10) {
                dispatch(setMoreDataAvailable(false));
              }
              dispatch(setIsLoadingJobs(false));
              dispatch(setIsLoadingMoreJobs(false));
            } else {
              console.error('The response data is not valid or missing.');
            }
          } else {
            console.error(`Request error. HTTP status: ${response.status}`);
          }
        })
        .catch((error) => {
          console.error('Request error:', error);
        });
    }
  };

  return (
    <form
      className='metasearchBarContainer'
      onSubmit={(e) => {
        e.preventDefault();
        searchData(queryJobs, 1);
      }}
    >
      <input
        placeholder='Do a job search...'
        className='metasearchBar'
        type='text'
        value={queryJobs}
        onChange={(e) => dispatch(setQueryJobs(e.target.value))}
      ></input>

      <button className='metasearchButton' type='submit'>
        <p>Search</p>
        <IoMdSearch />
      </button>
    </form>
  );
};

export default JobsSearchBar;
