import React from 'react';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Slices
import { setQueryArt } from '../../app/features/artSlice';
// Hooks
import { useGallery } from '../../hooks/useGallery';
// Icons
import { IoMdSearch } from 'react-icons/io';
// Styles
import './ArtSearchBar.css';

const ArtSearchBar = () => {
  const dispatch = useDispatch();
  const { getGalleryDataSocket } = useGallery();

  const { queryArt } = useSelector((state) => state.art);

  const allArtSources = [
    'NAGUS',
    'METMUSEUM',
    'DPLA',
    'DIGITALNZ',
    'RIJKSMUSEUM',
    'HARVARDMUSEUM',
    'COOPERMUSEUM',
  ];

  const searchData = (query) => {
    if (query?.length > 0) {
      getGalleryDataSocket(
        query,
        1,
        '',
        '',
        allArtSources,
        '',
        false,
        false,
        false,
        ''
      );
    }
  };

  return (
    <form
      className='metasearchBarContainer'
      onSubmit={(e) => {
        e.preventDefault();
        searchData(queryArt);
      }}
    >
      <input
        placeholder='Do a art search...'
        className='metasearchBar'
        type='text'
        value={queryArt}
        onChange={(e) => dispatch(setQueryArt(e.target.value))}
      ></input>
      <button className='metasearchButton' type='submit'>
        <p>Search</p>
        <IoMdSearch />
      </button>
    </form>
  );
};

export default ArtSearchBar;
