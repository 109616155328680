import React, { useEffect, useState } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Utils
import { fetchWithoutToken } from '../../utils/fetch';
// Components
import Container from '../../Components/Container';
import CardSchedule from '../../Components/CardSchedule';
// Styles
import './TutoringPage.css';

const TutoringPage = () => {
  // Slices
  const { institutionCode } = useSelector((state) => state.institution);

  // Variables
  const [dataTutoring, setDataTutoring] = useState([]);

  useEffect(() => {
    fetchWithoutToken
      .get(`/tutorships?institution_code=${institutionCode}`, {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      })
      .then((response) => {
        setDataTutoring(response.data.data);
      })
      .catch((e) => console.error(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMonth = (numberMonth) => {
    const month = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    if (numberMonth >= 1 && numberMonth <= 12) {
      return month[numberMonth - 1];
    } else {
      return 'invalid';
    }
  };

  return (
    <>
      <header className='headerTutoring'>
        <h1 className='headerTutoringTitle'>
          Schedule a <b>tutoring session</b>
        </h1>
        <p className='headerTutoringText'>
          Our tutors are available to clarify your doubts and strengthen your
          knowledge
        </p>
      </header>
      <main className='tutoring'>
        <Container className='tutoringContainer'>
          {Object.keys(dataTutoring)?.map((element, i) => {
            return (
              <details key={i} className='scheduleByMonth'>
                <summary className='sheduleTitle'>
                  <b className='sheduleTitle__list'>
                    {element !== undefined && getMonth(element.split('-')[1])}{' '}
                    {element !== undefined && element.split('-')[0]}{' '}
                  </b>
                  <p>{dataTutoring[element]?.length} Sessions available</p>
                </summary>

                <section className='scheduleContainer'>
                  {dataTutoring[element]?.map((tutoring, i) => {
                    return (
                      <CardSchedule
                        key={i}
                        course={tutoring.course}
                        date={tutoring.date}
                        instructor={tutoring.instructor}
                        module={tutoring.module}
                        time={tutoring.time}
                      />
                    );
                  })}
                </section>
              </details>
            );
          })}
        </Container>
      </main>
    </>
  );
};

export default TutoringPage;
