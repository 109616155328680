import { fetchWithoutToken } from '../utils/fetch';

export const getInfoUserForm = async (user, pass) => {
	try {
		const res = await fetchWithoutToken.post(`custom_login`, {
			username: user,
			password: pass,
		});
		return res;
	} catch (error) {
		return error.response;
	}
};
