import React from 'react';
import './CardSchedule.css';

const CardSchedule = ({ course, date, instructor, module, time }) => {
  const link =
    'https://us06web.zoom.us/j/89205460933?pwd=NUxLT0JQN05VaXBOc3BpdFdqNHlBUT09';

  return (
    <a href={link} target='_blank' rel='noreferrer' className='sheduleCard'>
      <div className='dateContainer'>
        <div>
          <p>DATE</p>
          <p>
            <b className='date'>{date.split('-')[2]}</b>
          </p>
        </div>
        <div>
          <p>TIME</p>
          <p>
            <b className='sheduleInformation'>{time}</b>
          </p>
        </div>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='icon icon-tabler icon-tabler-calendar-event'
          width='35'
          height='35'
          viewBox='0 0 24 24'
          strokeWidth='1.5'
          stroke='#ffffff'
          fill='none'
          strokeLinecap='round'
          strokeLinejoin='round'
        >
          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
          <path d='M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z' />
          <path d='M16 3l0 4' />
          <path d='M8 3l0 4' />
          <path d='M4 11l16 0' />
          <path d='M8 15h2v2h-2z' />
        </svg>
      </div>
      <div className='infoContainer'>
        <div className='instructor'>
          <div>
            <p>COURSE</p>
            <p>
              <b className='sheduleInformation'>{course}</b>
            </p>
          </div>
          <div>
            <p>INSTRUCTOR</p>
            <p>
              <b className='sheduleInformation'>{instructor}</b>
            </p>
          </div>
        </div>
        <div>
          <p>MODULE</p>
          <p>
            <b className='sheduleInformation'>{module}</b>
          </p>
        </div>
      </div>
    </a>
  );
};

export default CardSchedule;
