import { fetchWithoutToken } from '../utils/fetch';

export const getInstitutionTopicsAndDatabases = async (institutionCode) => {
	try {
		const res = await fetchWithoutToken.get(
			`/institutions/home-content/v2`,
			{
				headers: {
					Authorization: `Bearer ${localStorage.token}`,
				},
			}
		);

		return res;
	} catch (error) {
		console.error(error);
	}
};
