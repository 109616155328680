export const publicationDate = (date) => {
    if (date?.includes('hours') || date?.includes('minutes')) {
      return 'Today';
    } else {
      const currentDate = new Date();
      const publicationDate = new Date(date);

      const millisecondDifference = currentDate - publicationDate;
      const DaysDifference = Math.floor(
        millisecondDifference / (1000 * 60 * 60 * 24)
      );
      return DaysDifference === 0
        ? 'Today'
        : DaysDifference === 1
        ? 'Yesterday'
        : `${DaysDifference} days ago`;
    }
  };