import React from 'react';
// Redux
import { useSelector } from 'react-redux';
// Components
import NewsBaseSection from '../../Components/NewsBaseSection';
import NewsQuerySection from '../../Components/NewsQuerySection';
// Style
import './NewsPage.css';

const NewsPage = () => {
  const { queryNewsView } = useSelector((state) => state.news);

  if (queryNewsView) {
    return (
      <main className='news-query'>
        <NewsQuerySection />
      </main>
    );
  } else {
    return (
      <main className='news-base'>
        <NewsBaseSection />
      </main>
    );
  }
};

export default NewsPage;
