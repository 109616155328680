import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	elementaryData: [],
	isElementaryDataLoading: false,
	medicalData: [],
	isMedicalDataLoading: false,
	thesaurusData: [],
	isThesaurusDataLoading: false,
	isDictionaryDataFetched: false,
};

const dictionarySlice = createSlice({
	name: 'dictionary',
	initialState,
	reducers: {
		setIsDictionaryDataFetched: (state, action) => {
			state.isDictionaryDataFetched = action.payload;
		},
		setElementaryData: (state, action) => {
			state.elementaryData = action.payload;
		},
		setIsElementaryDataLoading: (state, action) => {
			state.isElementaryDataLoading = action.payload;
		},
		setMedicalData: (state, action) => {
			state.medicalData = action.payload;
		},
		setIsMedicalDataLoading: (state, action) => {
			state.isMedicalDataLoading = action.payload;
		},
		setThesaurusData: (state, action) => {
			state.thesaurusData = action.payload;
		},
		setIsThesaurusDataLoading: (state, action) => {
			state.isThesaurusDataLoading = action.payload;
		},
		resetDictionaty: (state) => {
			state.elementaryData = [];
			state.isElementaryDataLoading = false;
			state.medicalData = [];
			state.isMedicalDataLoading = false;
			state.thesaurusData = [];
			state.isThesaurusDataLoading = false;
			state.isDictionaryDataFetched = false;
		},
	},
});

export const {
	setIsDictionaryDataFetched,
	setElementaryData,
	setIsElementaryDataLoading,
	setMedicalData,
	setIsMedicalDataLoading,
	setThesaurusData,
	setIsThesaurusDataLoading,
	resetDictionaty,
} = dictionarySlice.actions;

export default dictionarySlice.reducer;
