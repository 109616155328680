export const getAddLibraryData = (institutionCode) => {
  switch (institutionCode) {
    case 'NIGHTINGALE':
      return data_NIGHTINGALE;
    case 'CBT':
      return data_CBT;

    default:
      return [];
  }
};

const data_NIGHTINGALE = [
  {
    title:
      'Problem in the profession: How and why writing skills in nursing must be improved',
    authors: ['Joyce E. Johnson', 'Kevin Rulo'],
    abstract:
      "As a profession, nursing is obligated to disseminate knowledge by publishing research in the professional literature. Beyond producing scholarly work for publication, nurses need writing skills to complete doctoral dissertations and scholarly projects, and to succeed in obtaining funds for new nurse-directed business ventures. Ultimately, good writing skills are essential for the future of the nursing profession. In this article, we describe the critical role of writing in nursing, and offer a practical 10-point strategy for improving the writing ability of individual advanced practice nurses who need to improve their writing skills. This article also offers suggestions for increasing nursing's surveillance of nurses' writing skills such as increasing the emphasis on writing instruction as a priority in today's nursing graduate school curriculum, greater writing support for nurses who are writing dissertations and scholarly projects, evaluating writing programs, and monitoring the completion rate of nursing dissertations.",
    publication_date: '2019',
    url: 'https://www.sciencedirect.com/science/article/abs/pii/S8755722318300619',
    type: 'ARTICLE',
    resource_origin: 'Elsevier',
    peer_reviewed: true,
    words: ['nursing', 'writing', 'skills'],
  },
];

const data_CBT = [
  {
    title: 'National Electrical Code (NEC)',
    authors: ['John A Camara', 'BS', 'MS', 'PE', 'TF'],
    abstract:
      'The Code is official endorsed by ANSI (American National Standards Institute). The National Fire Protection Association (NFPA) committee responsible for the code is known as ANSI Standards Committee C1. The Code is utilized nationwide with local jurisdictions adoption en masse though with the occasional supplemental additions or deletions. The Code applies to electrical installations within or on public and private buildings up to and including connection to the providing power supply.',
    publication_date: '2023',
    url: 'https://s3.amazonaws.com/suncam/docs/414.pdf',
    type: 'BOOK',
    resource_origin: 'National Electrical Code',
    peer_reviewed: false,
    words: ['national', 'electrical', 'code'],
  },
];
