import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  interestingLinks: [],
};

const interestingLinksSlice = createSlice({
  name: 'interestingLinks',
  initialState,
  reducers: {
    setInterestingLinks: (state, action) => {
      state.interestingLinks = action.payload;
    },
  },
});

export const { setInterestingLinks } = interestingLinksSlice.actions;

export default interestingLinksSlice.reducer;
