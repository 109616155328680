import React from 'react';
// Redux
import { useSelector } from 'react-redux';
// Components
import Container from '../Container';
import CardInterestingLinksHome from '../CardInterestingLinksHome';
// Utils
import { filterByTitle } from '../../utils/filterByTitle';
// Styles
import './AcademicResources.css';

const AcademicResources = () => {
  // Slices
  const { interestingLinks } = useSelector((state) => state.interestingLinks);
  const { institutionCode } = useSelector((state) => state.institution);

  const getResources = (resources, code) => {
    const resourcesToShow = filterByTitle(resources, code);
    return resourcesToShow.length === 0
      ? interestingLinks.slice(0, 3)
      : resourcesToShow;
  };

  return (
    <section className='resourcesHome'>
      <Container>
        <div className='resourcesHomeContainer'>
          {institutionCode === 'MOORE' ? (
            <h2 className='resourcesTitle'>
              <b>Deepening Links</b>
            </h2>
          ) : (
            <h2 className='resourcesTitle'>
              Most helpful <b>academic resources</b>
            </h2>
          )}
          <div className='resourcesHomeList'>
            {interestingLinks &&
              getResources(interestingLinks, institutionCode).map(
                (resource, i) => {
                  return (
                    <CardInterestingLinksHome
                      key={i}
                      title={resource.title}
                      image_url={resource.image}
                      url={resource.url}
                      description={resource.program}
                      type='resource'
                      resource_origin={resource.program}
                    />
                  );
                }
              )}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default AcademicResources;
