import img1 from '../Images/Tutorials/1-min.png';
import img2 from '../Images/Tutorials/2-min.png';
import img3 from '../Images/Tutorials/3-min.png';
import img4 from '../Images/Tutorials/4-min.png';
import img5 from '../Images/Tutorials/5-min.png';
import img6 from '../Images/Tutorials/6-min.png';
import img7 from '../Images/Tutorials/7-min.png';
import img8 from '../Images/Tutorials/8-min.png';
import img9 from '../Images/Tutorials/9-min.png';
import img10 from '../Images/Tutorials/10-min.png';
import img11 from '../Images/Tutorials/11-min.png';
import img12 from '../Images/Tutorials/12-min.png';
// CBT Vieos
import img13 from '../Images/Tutorials/13-min.png';
import img14 from '../Images/Tutorials/14-min.png';
import img15 from '../Images/Tutorials/15-min.png';
import img16 from '../Images/Tutorials/16-min.png';
import img17 from '../Images/Tutorials/17-min.png';
import img18 from '../Images/Tutorials/18-min.png';
import img19 from '../Images/Tutorials/19-min.png';
import img20 from '../Images/Tutorials/20-min.png';
import img21 from '../Images/Tutorials/21-min.png';

export const getVideosTutorials = ({ institution_code = 'DEMO' }) => {
  let data = [
    {
      title: 'APA Style - 7th Edition',
      image: img1,
      url: '857669859',
      duration: '5:08',
      description:
        'Public speaking is not easy for everyone. It can be nerve racking and even scary. But, in this episode of Crash Course Business Soft Skills, Evelyn talks to us about S.U.C.C.E.S and how we can use it to help us be prepared to speak to a group.',
      language: 'EN',
      type: 'vimeo',
    },
    {
      title: 'Estilo APA - 7th Edición',
      image: img2,
      url: '857670406',
      duration: '5:08',
      description:
        'Most of us struggle with time management. It is normal. But, things need to get done both at work and in your personal life. So, in this episode of Crash Course Business, Evelyn gives us all some ways to structure our checklist and make sure we get our work done while still not forgetting to take care of ourselves.',
      language: 'ES',
      type: 'vimeo',
    },
    {
      title: 'PLAGIARISM',
      image: img12,
      url: '910975128',
      duration: '3:01',
      description: 'Plagiarism',
      language: 'EN',
      type: 'vimeo',
    },
    {
      title: 'How to Outline & Structure Your College Essay',
      image: img3,
      url: 'FWw3C18lLmQ',
      duration: '11:32',
      description: `Outlining your personal statement is crucial if you want to save time and figure out whether your topic is likely to work or not. In this video, I'll share tips on outlining and structuring your college essay, walk you through the only two structures you really ever need, and analyze two simple outlines that lead to great essays.Do you want to improve your critical thinking skills in the workplace? Learn and develop this disciplined process through Dr. Grace Lee's executive coaching program`,
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'How to write a good essay',
      image: img4,
      url: 'liyFKUFCQno',
      duration: '8:23',
      description: `How to write an essay- brief essays and use the principles to expand to longer essays/ even a thesis
you might also wish to check the video on Interview technique (now on this channel too!)and  my new video on essay writing and Harvard Referencing here`,
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'How to Write an Academic Essay in 10 Minutes or Less',
      image: img5,
      url: 'tBAbIobh3uo',
      duration: '10:56',
      description: `Use these paragraph formulas to write your academic essay in 10-minutes or less. Don't stare at the page, trying to write sentences in your head. Instead, pull out these formulas and fill in the blanks. Let the formulas do the heavy lifting.`,
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'APA In-Text Citations Made Easy',
      image: img6,
      url: 'LbyJdMz-Ato',
      duration: '7:37',
      description: `Whenever you use a source, you must have an in-text citation and a reference citation. They're a matched pair--like shoes or gloves. What are the differences between an APA in-text citation and an APA reference citation? How do the two work together?`,
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'The Great College Essay Test | How to Revise Your College Essay',
      image: img7,
      url: '1-wApCQBu_8',
      duration: '5:57',
      description: `Put your College Essay to the test-- The Great College Essay Test, that is! This exercise will show you how to revise your college essay in 5 easy steps. `,
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'CÓMO HACER UN ENSAYO EN 3 PASOS ¡más fácil que nunca!.',
      image: img8,
      url: 'FwgeiavmoiI',
      duration: '5:27',
      description: ` ¡Wooow! ¡En solo 3 pasos ¡3 PASOS! aprenderás a redactar un ensayo. ¡No le temas a los ensayos! ¡Yo te explico cómo hacerlo de una forma sencilla! ¡Bienvenidos!`,
      language: 'ES',
      type: 'youtube',
    },
    {
      title: '5 claves para redactar mejor tus textos',
      image: img9,
      url: 'pJPm2CGTPgc',
      duration: '8:23',
      description: `Te voy a dar cinco consejos para que subas automáticamente un escalón en tu nivel de #redacción: sé breve, sé sencillo, sé claro, sé preciso y revisa. Los detalles los tienes en el vídeo.`,
      language: 'ES',
      type: 'youtube',
    },
    {
      title: '¿Cómo hacer el desarrollo de un ensayo?',
      image: img10,
      url: 'DMY-9DA3Vyw',
      duration: '6:51',
      description: `Aprende cómo hacer el desarrollo de un ensayo con las estrategias que discuto en este video.`,
      language: 'ES',
      type: 'youtube',
    },
    {
      title:
        '7 pasos para ESCRIBIR ENSAYO | Consejos de escritura | Javier Miró',
      image: img11,
      url: 'r3sqNfKOi-g',
      duration: '11:11',
      description: `7 pasos para ESCRIBIR ENSAYO | Consejos de escritura | Javier Miró`,
      language: 'ES',
      type: 'youtube',
    },
  ];

  const data_CBT = [
    {
      title: 'Fire Protection Systems: Building Codes and Safety Standards',
      image: img13,
      url: 'K_ox45vigS4',
      duration: '6:03',
      description: `Keeping people safe and having less damage occur to a building are the two top priorities when installing a Fire Safe Protection System.`,
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Jim Pauley talks about free access to NFPA codes and standards',
      image: img14,
      url: '-RqNwXBmCao',
      duration: '1:01',
      description: `Keeping people safe and having less damage occur to a building are the two top priorities when installing a Fire Safe Protection System.`,
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Fire Alarm Basics',
      image: img15,
      url: 'lGxGUwqC9IE',
      duration: '36:23',
      description: `Have you ever wondered as a responding firefighter what are the basic components tied into a fire alarm system? In this video Richard Roberts of Honeywell, Dan Finnegan with Siemens, and IAFC Fire and Life Safety Section (FLSS) Board members Andy King and Michael O’Brian discuss the basics of all fire alarm systems.`,
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Low voltage cabling pre-wire for audio and security systems',
      image: img16,
      url: 'uEMqEKKw69k',
      duration: '13:41',
      description: `In this video, take a look at a typical home low voltage pre-wire for security and audio system wiring.  Includes pre-wire of speakers, keypads, audio sources, and security hardware for a smart home.`,
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'History of the America in 25 minutes',
      image: img17,
      url: 'k3HUPHMYEJw',
      duration: '25:45',
      description: `This is the History of the America. Featuring Christopher Columbus, Native Americans, American Revolution, George Washington, other American presidents all the way to modern times. Obviously could not fit everything into one video and had to narrow down some events for the sake of time. It includes all the US states, New York, California, Texas, etc.`,
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'How to Start a Speech: The Best (and Worst) Speech Openers',
      image: img18,
      url: '7tzentBmmUc',
      duration: '6:59',
      description: `In this video, I will teach you how to begin a speech and grab your audience's attention, how to get to your point, and how you can leave a good lasting impression. I go through a free speech openers that others have done to show how effective these openers can be, and I talk about what not to do for your speech.`,
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'HOW TO WRITE A COMPOSITION',
      image: img19,
      url: 'RFwui6V32nc',
      duration: '6:37',
      description: `Hello Students! 'How to write a Composition', My today's video will help you to create your own piece of writing.
Hello Dear Students! Are you aiming to get good grades? No need to worry anymore! The Modern learning is here to help you out. Our videos will help you gain more knowledge in an easier and more fun way. We will teach you every subject with detail so it'll be much more easier for you. Learning is not a difficult sport, unless you have a good teacher and you have 'The Modern Learning'. Our team is working day and night to bring the best and helpful content for you. 
If you want to appreciate our work you can click the subscribe button, like our videos, write a comment and share with other students who need help, it's totally free of cost. And remember, learning never exhausts the mind.
Good Luck:)`,
      language: 'EN',
      type: 'youtube',
    },
    {
      title:
        'Top Interview Tips: Common Questions, Nonverbal Communication & More | Indeed',
      image: img20,
      url: 'HG68Ymazo18',
      duration: '4:57',
      description: `Looking for interview tips? In this video, we dissect an entire job interview from start to finish. We analyze everything from common interview questions to etiquette and how to follow up. Our key takeaways are:`,
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Overview of Spanish Verb Tenses, Conjugations, and Uses',
      image: img21,
      url: 'HG68Ymazo18',
      duration: '37:52',
      description: `This video provides and extensive overview of the Spanish verb system. It covers the forms and uses of 15 verb verb tenses, providing lots of examples in Spanish and English.`,
      language: 'EN',
      type: 'youtube',
    },
  ];

  if (institution_code === 'CBT') {
    data = [...data, ...data_CBT];
  }

  return data;
};
