import React from 'react';
// Redux
import { useSelector } from 'react-redux';
// Services
import { sendUserSearchInformation } from '../../services/sendUserSearchInformation';
// Images
import PlaceholderImage from '../../Images/placeholder-image.png';
import PlayButtonImage from '../../Images/play-button.svg';
// Styles
import './styles.css';

const CardMediaResources = ({ image, timer, title, description, url }) => {
	const { user_email } = useSelector((state) => state.user);
	const { institutionCode } = useSelector((state) => state.institution);

	const handleClicCard = () => {
		sendUserSearchInformation(
			title,
			'Google scholar',
			url,
			'Media',
			user_email,
			institutionCode
		);
		window.open(url, '_blank');
	};

	return (
		<div
			className='media-resource-card'
			onClick={handleClicCard}
			role='button'
			tabIndex='0'
		>
			<div className='thumbnail_image'>
				<div className='thumbnail_image__play-button'>
					<img src={PlayButtonImage} alt='play' className='button-svg' />
				</div>

				<img
					src={image ? image : PlaceholderImage}
					alt='thumbnail'
					className='thumbnail'
				/>
				{timer && <div className='thumbnail_timer'>{timer}</div>}
			</div>

			<div className='resource_content'>
				{title && <div className='resource_title'>{title}</div>}
				{description && (
					<div className='resource_description'>{description}</div>
				)}
			</div>
		</div>
	);
};

export default CardMediaResources;
