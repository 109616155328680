import React from 'react';
import { setSelectedDetailArt } from '../../app/features/artSlice';
import { useDispatch, useSelector } from 'react-redux';
import { sendUserSearchInformation } from '../../services/sendUserSearchInformation';
// Images from different resources
// import videoArtImage from '../../Images/ArtPage/video-art.png';
import imageArtImage from '../../Images/ArtPage/image-art.png';
// import audioArtImage from '../../Images/ArtPage/audio-art.png';
// Default images
// import default_video from '../../Images/ArtPage/default_video.jpg';
// import default_audio from '../../Images/ArtPage/default_audio.jpg';
import default_noData from '../../Images/ArtPage/default_noData.jpg';
import './CardArt.css';

const CardArt = ({ data, position }) => {
	const { user_email } = useSelector((state) => state.user);
	const { institutionCode } = useSelector((state) => state.institution);

	const dispatch = useDispatch();
	const handleDetailArt = (data) => {
		dispatch(setSelectedDetailArt(data));
		sendUserSearchInformation(
			data.title,
			data.source_code,
			data.url,
			'art',
			user_email,
			institutionCode
		);
	};

	return (
		<div
			className={
				position === 1
					? 'backgroungImageCard backgroungImageCardBig'
					: 'backgroungImageCard'
			}
			style={{
				backgroundImage:
					data?.images?.length > 0
						? `url(${data.images[0]})`
						: `url(${default_noData})`,
				// data?.image_url?.length > 0
				// 	? `url(${data?.image_url})`
				// 	: data?.video_url?.length > 0
				// 	? `url(${default_video})`
				// 	: data?.audio_url?.length > 0
				// 	? `url(${default_audio})`
				// 	: `url(${default_noData})`,
			}}
			role='button'
			onClick={() => handleDetailArt(data)}
		>
			<div className='overlay'></div>
			{
				// data.video_url.length > 0 ||
				data?.images?.length > 0 && (
					// data.audio_url.length > 0
					<div className='containerMediaIconsArt'>
						{/* {data.video_url.length > 0 && (
						<img alt='video art' src={videoArtImage}></img>
					)} */}
						{data?.images?.length > 0 && (
							<img alt='from art' src={imageArtImage || default_noData}></img>
						)}
						{/* {data.audio_url.length > 0 && (
						<img alt='audio art' src={audioArtImage}></img>
					)} */}
					</div>
				)
			}

			<h3
				className={
					data?.description
						? 'titleCardArt titleCardArtDescription'
						: 'titleCardArt'
				}
			>
				{data?.title}
			</h3>
			{data?.description && (
				<div className='descriptionCardArt'>
					<p>{data?.description}</p>
					<div>
						<button>See more</button>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							className='icon icon-tabler icon-tabler-arrow-right'
							width='20'
							height='20'
							viewBox='0 0 24 24'
							strokeWidth='1.5'
							stroke='#000000'
							fill='none'
							strokeLinecap='round'
							strokeLinejoin='round'
						>
							<path stroke='none' d='M0 0h24v24H0z' fill='none' />
							<path d='M5 12l14 0' />
							<path d='M13 18l6 -6' />
							<path d='M13 6l6 6' />
						</svg>
					</div>
				</div>
			)}
		</div>
	);
};

export default CardArt;
