import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isLoggedIn: false,
	public_token: '',
	public_token_expires: '',
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setPublicToken: (state, action) => {
			state.public_token = action.payload;
		},
		setPublicTokenExpires: (state, action) => {
			state.public_token_expires = action.payload;
		},
		setIsLoggedIn: (state, action) => {
			state.isLoggedIn = action.payload; //Cuando se haga la llamada al auth, pasamos esta accion para saber si usuario esta logeado o no
		},
	},
});

export const { setIsLoggedIn, setPublicToken, setPublicTokenExpires } =
	authSlice.actions;

export default authSlice.reducer;
