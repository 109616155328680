import { fetchWithoutToken } from '../utils/fetch';

export const getInfoUserTalent = async () => {
	try {
		const res = await fetchWithoutToken.get(
			`https://aquinas.a2hosted.com/broward/talentlms_api.php`,
			{
				headers: [
					{ key: 'Access-Control-Allow-Credentials', value: 'true' },
					{ key: 'Access-Control-Allow-Origin', value: '*' },
					{
						key: 'Access-Control-Allow-Methods',
						value: 'GET,OPTIONS,PATCH,DELETE,POST,PUT',
					},
					{
						key: 'Access-Control-Allow-Headers',
						value:
							'X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version',
					},
				],
			}
		);
		return res;
	} catch (error) {
		console.error(error);
	}
};
