import React from 'react';
// Redux
import { useSelector } from 'react-redux';
// Services
import { sendUserSearchInformation } from '../../services/sendUserSearchInformation';
// Styles
import './CardNewsSearched.css';

const CardNewsSearched = ({
  title,
  url,
  image_url,
  resource_origin,
  publication_date,
  description,
}) => {
  const { user_email } = useSelector((state) => state.user);
  const { institutionCode } = useSelector((state) => state.institution);

  const handleClicCard = () => {
    sendUserSearchInformation(
      title,
      resource_origin,
      url,
      'news',
      user_email,
      institutionCode
    );
    window.open(url, '_blank');
  };

  const publicationDate = (date) => {
    if (date?.includes('hours') || date?.includes('minutes')) {
      return 'Today';
    } else {
      const currentDate = new Date();
      const publicationDate = new Date(date);

      const millisecondDifference = currentDate - publicationDate;
      const DaysDifference = Math.floor(
        millisecondDifference / (1000 * 60 * 60 * 24)
      );
      return DaysDifference === 0
        ? 'Today'
        : DaysDifference === 1
        ? 'Yesterday'
        : `${DaysDifference} days ago`;
    }
  };

  return (
    <div className='cardNewsSearched'>
      <div className='containerImage'>
        <img src={image_url} alt={`${title}`} loading='lazy' />
      </div>

      <div className='cardNewsSearchedContainer'>
        <div className='info'>
          <p className='sourceNewsSearched'>{resource_origin}</p>
          {publication_date && (
            <p className='publicationDateNewsSearched'>
              {publicationDate(publication_date)}
            </p>
          )}
        </div>

        <h4 className='titleNewsSearched'>{title?.replace('+', ' ')}</h4>
        <p className='descriptionNewsSearched'>{description}</p>
        <button className='showMore' onClick={handleClicCard}>
          Show More
        </button>
      </div>
    </div>
  );
};

export default CardNewsSearched;
