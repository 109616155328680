import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  art: [],
  sources: [],
  queryArt: '',
  totalArt: 0,
  isLoadingArt: false,
  isLoadingMoreData: false,
  colors: '',
  periodSelected: '',
  selectedSourcesArt: [],
  allArtSources: [],
  selectedStartYear: '',
  selectedEndYear: '',
  selectedColor: '',
  pageArt: 1,
  selectedDetailArt: {},
  onlyImage: false,
  onlyVideo: false,
  onlyAudio: false,
  filters: {
    queryFetched: '',
    sourcesFetchedArt: [],
    colorsFetched: '',
    periodFetched: '',
    startYearFetched: '',
    endYearFetched: '',
    pageFetched: 1,
    onlyImageFetched: false,
    onlyVideoFetched: false,
    onlyAudioFetched: false,
  },
};

const artSlice = createSlice({
  name: 'art',
  initialState,
  reducers: {
    setArt: (state, action) => {
      state.art = [...state.art, ...action.payload];
    },
    setColors: (state, action) => {
      state.colors = action.payload;
    },
    setSources: (state, action) => {
      const newSources = Array.isArray(action.payload)
        ? action.payload
        : [action.payload];
      state.sources = [...state.sources, ...newSources];
    },
    setQueryArt: (state, action) => {
      state.queryArt = action.payload;
    },
    setTotalArt: (state, action) => {
      state.totalArt = action.payload;
    },
    setIsLoadingArt: (state, action) => {
      state.isLoadingArt = action.payload;
    },
    setIsLoadingMoreData: (state, action) => {
      state.isLoadingMoreData = action.payload;
    },
    setSelectedSourcesArt: (state, action) => {
      state.selectedSourcesArt = action.payload;
    },
    setAllArtSources: (state, action) => {
      state.allArtSources = action.payload;
    },
    resetSources: (state) => {
      state.sources = [];
    },
    setPeriodSelected: (state, action) => {
      state.periodSelected = action.payload;
    },
    setSelectedStartYear: (state, action) => {
      state.selectedStartYear = action.payload;
    },
    setSelectedEndYear: (state, action) => {
      state.selectedEndYear = action.payload;
    },
    setSelectedColor: (state, action) => {
      const newColor = action.payload;
      if (newColor === state.selectedColor) {
        state.selectedColor = '';
      } else {
        state.selectedColor = newColor;
      }
    },
    setPageArt: (state, action) => {
      state.pageArt = action.payload;
    },
    setSelectedDetailArt: (state, action) => {
      state.selectedDetailArt = action.payload;
    },
    setOnlyImage: (state, action) => {
      state.onlyImage = action.payload;
    },
    setOnlyVideo: (state, action) => {
      state.onlyVideo = action.payload;
    },
    setOnlyAudio: (state, action) => {
      state.onlyAudio = action.payload;
    },
    resetArt: (state) => {
      state.art = [];
      state.sources = [];
      state.colors = '';
      state.totalArt = 0;
      state.periodSelected = '';
      state.selectedStartYear = '';
      state.selectedEndYear = '';
      state.isLoadingArt = false;
      state.isLoadingMoreData = false;
      state.pageArt = 1;
      state.selectedDetailArt = {};
      state.onlyImage = false;
      state.onlyVideo = false;
      state.onlyAudio = false;
    },
    // Filtros para datos buscados (Fetched)
    setQueryFetched: (state, action) => {
      state.filters.queryFetched = action.payload;
    },
    setSourcesFetchedArt: (state, action) => {
      state.filters.sourcesFetchedArt = action.payload;
    },
    setColorsFetched: (state, action) => {
      state.filters.colorsFetched = action.payload;
    },
    setPeriodFetched: (state, action) => {
      state.filters.periodFetched = action.payload;
    },
    setStartYearFetched: (state, action) => {
      state.filters.startYearFetched = action.payload;
    },
    setEndYearFetched: (state, action) => {
      state.filters.endYearFetched = action.payload;
    },
    setPageFetched: (state, action) => {
      state.filters.pageFetched = action.payload;
    },
    setOnlyImageFetched: (state, action) => {
      state.filters.onlyImageFetched = action.payload;
    },
    setOnlyVideoFetched: (state, action) => {
      state.filters.onlyVideoFetched = action.payload;
    },
    setOnlyAudioFetched: (state, action) => {
      state.filters.onlyAudioFetched = action.payload;
    },

    // Reinicio de filtros
    resetFilters: (state) => {
      state.selectedSourcesArt = [];
      state.selectedStartYear = '';
      state.selectedEndYear = '';
      state.selectedColor = '';
      state.filters.sourcesFetchedArt = [];
      state.filters.colorsFetched = '';
      // state.filters.periodFetched = '';
      state.filters.startYearFetched = '';
      state.filters.endYearFetched = '';
      state.filters.pageFetched = 1;
      state.filters.onlyImageFetched = false;
      state.filters.onlyVideoFetched = false;
      state.filters.onlyAudioFetched = false;
    },
  },
});

export const {
  setArt,
  setSources,
  resetSources,
  setAllArtSources,
  setQueryArt,
  setTotalArt,
  setIsLoadingArt,
  setIsLoadingMoreData,
  setColors,
  setSelectedSourcesArt,
  setPeriodSelected,
  setSelectedStartYear,
  setSelectedEndYear,
  setSelectedColor,
  setPageArt,
  setSelectedDetailArt,
  setOnlyImage,
  setOnlyVideo,
  setOnlyAudio,
  resetArt,
  // Filters
  setQueryFetched,
  setSourcesFetchedArt,
  setColorsFetched,
  setPeriodFetched,
  setStartYearFetched,
  setEndYearFetched,
  setPageFetched,
  setOnlyImageFetched,
  setOnlyVideoFetched,
  setOnlyAudioFetched,
  resetFilters,
} = artSlice.actions;

export default artSlice.reducer;
