import { useDispatch, useSelector } from 'react-redux';
import { setSocketPatents } from '../app/features/metasearchSlice';
import {
  setPatents,
  // setSourcesPatents,
  setTotalPatents,
  setIsLoadingPatents,
  setIsLoadingMoreData,
  setSelectedSourcesPatents,
  setSourcesFetchedPatents,
  resetPatents,
  setStartYear,
  setEndYear,
  setStartYearFetched,
  setEndYearFetched,
  setPagePatentsFetched,
  setQueryPatents,
  setQueryFetched,
  setIsPatentsDataFetched,
} from '../app/features/patentsSlice';

export const usePatents = () => {
  const dispatch = useDispatch();
  const metaSearchStore = useSelector((state) => state.metasearch);
  const { institutionCode } = useSelector((state) => state.institution);

  const getPatentsDataSocket = async (
    queryPatents = '',
    pagePatents = 1,
    startYear = '',
    endYear = '',
    sources = []
  ) => {
    // Controlador de conteo en respuesta Single del Websocket
    let totalPatentsSingle = 0;
    // Guardando informacion
    if (pagePatents === 1) {
      dispatch(resetPatents());
      dispatch(setIsPatentsDataFetched(true));
      dispatch(setIsLoadingPatents(true));
      dispatch(setIsLoadingMoreData(true));
      dispatch(setQueryPatents(queryPatents));
      dispatch(setStartYear(startYear));
      dispatch(setEndYear(endYear));
      dispatch(setSelectedSourcesPatents(sources));
      // Filters
      dispatch(setQueryFetched(queryPatents));
      dispatch(setSourcesFetchedPatents(sources));
      dispatch(setStartYearFetched(startYear));
      dispatch(setEndYearFetched(endYear));
    }
    dispatch(setPagePatentsFetched(pagePatents));

    // Verifica si hay una conexión anterior y la cierra
    if (metaSearchStore && metaSearchStore.socketPatents instanceof WebSocket) {
      metaSearchStore.socketPatents.close();
    }

    // Se crea la configuración para la conexión al web socket
    const websocketPatents = new WebSocket(
      // `ws://localhost:8000/search?auth_token=${localStorage.token}&version=v2`
      `wss://olib-api.aquinasnetwork.com/search?auth_token=${localStorage.token}&version=v2`
    );

    // Se crea el nuevo estado para la variable "socket" que verifica si hay o no una conexión previa
    dispatch(setSocketPatents(websocketPatents));

    // Envia la petición al web socket para iniciar la conexión
    websocketPatents.onopen = function () {
      const socketQuery = {
        query: queryPatents,
        params: {
          sources,
          start_year: startYear,
          end_year: endYear,
        },
        institution_code: institutionCode,
        cache: false,
        page: pagePatents,
        source_type: 'patents',
        version: 2,
      };

      websocketPatents.send(JSON.stringify(socketQuery));

      // Recibe la información del websocketBooks
      websocketPatents.onmessage = function (event) {
        const dataSource = JSON.parse(event.data);

        if (dataSource.type === 'summary') {
          dispatch(setTotalPatents(dataSource?.count));
          dispatch(setIsLoadingMoreData(false));
          dispatch(setIsLoadingPatents(false));
          websocketPatents.close();
        }

        if (dataSource.type === 'single') {
          dispatch(setPatents([...dataSource.data.results]));
          // const source_info = {
          //   name: dataSource.data.name_source,
          //   code: dataSource.data.code_source,
          //   count: dataSource.data.count,
          // };
          totalPatentsSingle = totalPatentsSingle + dataSource.data.count;
          dispatch(setTotalPatents(totalPatentsSingle));
          // pagePatents === 1 && dispatch(setSourcesPatents(source_info));
          dataSource.data.count > 0 && dispatch(setIsLoadingPatents(false));
        }
      };
    };

    // Cierre de la conexión por tiempo
    setTimeout(() => {
      dispatch(setIsLoadingPatents(false));
      dispatch(setIsLoadingMoreData(false));
      websocketPatents.close();
    }, 120000);
  };

  return { getPatentsDataSocket };
};
