import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  multimedia: [],
  is_multimedia_loading: false,
  isMediaDataFetched: false,
  total_multimedia: 0,
  pageMultimediaShowed: 1,
  pageMultimediaFetched: 1,
};

const multimediaSlice = createSlice({
  name: 'multimedia',
  initialState,
  reducers: {
    setMultimedia: (state, action) => {
      state.multimedia = [...state.multimedia, ...action.payload];
    },
    setIsMultimediaLoading: (state, action) => {
      state.is_multimedia_loading = action.payload;
    },
    setIsMediaDataFetched: (state, action) => {
      state.isMediaDataFetched = action.payload;
    },
    setTotalMultimedia: (state, action) => {
      state.total_multimedia = action.payload;
    },
    setPageMultimediaShowed: (state, action) => {
      state.pageMultimediaShowed = action.payload;
    },
    setPageMultimediaFetched: (state, action) => {
      state.pageMultimediaFetched = action.payload;
    },
    resetMultimedia: (state) => {
      state.multimedia = [];
      state.isMediaDataFetched = false;
      state.is_multimedia_loading = false;
      state.total_multimedia = 0;
      state.pageMultimediaShowed = 1;
      state.pageMultimediaFetched = 1;
    },
  },
});

export const {
  setMultimedia,
  setIsMultimediaLoading,
  setIsMediaDataFetched,
  setTotalMultimedia,
  resetMultimedia,
  setPageMultimediaShowed,
  setPageMultimediaFetched,
} = multimediaSlice.actions;

export default multimediaSlice.reducer;
