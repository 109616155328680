export const areListsDiff = (array1, array2) => {
	// Verificar si las longitudes de los arrays son diferentes
	if (array1?.length !== array2?.length) {
		return true;
	}

	// Ordenar ambos arrays
	const sortedArray1 = array1?.slice().sort();
	const sortedArray2 = array2?.slice().sort();

	// Verificar elemento por elemento
	for (let i = 0; i < sortedArray1?.length; i++) {
		if (sortedArray1[i] !== sortedArray2[i]) {
			return true;
		}
	}

	// Si no se encontraron diferencias, los arrays son iguales
	return false;
};
