import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// Views
import Router from './Views/Routes/router';
// Redux
import { store } from './app/store';
import { Provider } from 'react-redux';
// Redux Persist
import persistStore from 'redux-persist/es/persistStore';
import { PersistGate } from 'redux-persist/integration/react';
// Hooks
import { useScrollToTop } from './hooks/useScrollToTop';
// Components
import Chat from './Components/Chat';
import SearchBar from './Components/SearchBar';
import NavigationBarResources from './Components/NavigationBarResources';
// Styles
import './App.css';

let persistor = persistStore(store);

function App() {
  const { pathname } = useLocation();
  const { handleScrollToTop } = useScrollToTop();

  // This is to make the page go to the top when user changes the page.
  useEffect(() => {
    handleScrollToTop();
  }, [pathname, handleScrollToTop]);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <div className='App'>
          <NavigationBarResources />
          {/* <Chat /> */}
          <SearchBar />
          <Router />
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
