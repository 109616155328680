import business_management from '../Images/LibraryVideo/business_management.webp';
import education from '../Images/LibraryVideo/education.webp';
import policy from '../Images/LibraryVideo/policy.webp';
import information_technology from '../Images/LibraryVideo/information_technology.webp';
import marketing from '../Images/LibraryVideo/marketing.webp';
import ethics from '../Images/LibraryVideo/ethics.webp';

export const getLibraryVideos = (query) => {
  const data = [
    {
      title: 'Explicación Nivel 1—Planeación Estratégica',
      image: business_management,
      url: 'KziWsR61aSk',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title: 'Explorando los Beneficios de la Planeación Estratégica Aplicada',
      image: business_management,
      url: 'ep-PmjoohaY',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title:
        'II Congreso internacional de Innovación en Administración Pública',
      image: business_management,
      url: 'yDNw9gndOWg',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title:
        'Lineamientos para la elaboración del Plan Estratégico del Talento Humano',
      image: business_management,
      url: 'Xc2eAMLmGio',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title: 'Planificación Estratégica—Innovación Empresarial',
      image: business_management,
      url: 'sSbbH3oHvyE',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title: 'Business Management Essentials',
      image: business_management,
      url: 'p283p96Dc5o',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: '3 Focuses For Small Business Management',
      image: business_management,
      url: 'Ay-Z031i1Ok',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Engineering business management',
      image: business_management,
      url: 'IylR9j6nSdw',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Transforming Sustainable Development',
      image: business_management,
      url: 'h966e0M5X4Q',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'The Importance Of Management Accounts For Your Business',
      image: business_management,
      url: 'cRQgTbE4nqY',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Masterclass: Hablemos de Reskilling y Upskilling',
      image: business_management,
      url: 'dxHtYbOJ4_M',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title: 'TED Talk Strategies for Business Growth',
      image: business_management,
      url: 'RdXjfd8kiVY',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'The Art of Building Business Strategy',
      image: business_management,
      url: 'yH_oXf-pQc0',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Artificial Intelligence, Future of Business',
      image: business_management,
      url: '2Ikogbcjz5A',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title:
        'Seminario de alto nivel: “Hacia la Segunda Cumbre Mundial sobre Desarrollo Social"',
      image: business_management,
      url: 'K7zS4fb5GaU',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title:
        'Quinta Conferencia Regional sobre Población y Desarrollo de América Latina y el Caribe',
      image: business_management,
      url: 'u5e2EBVtxbc',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title: 'Conferencia: Economía del cuidado',
      image: business_management,
      url: 'Tjx5CC9uNQg',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title: '30 Years of Business Knowledge in 2hrs 26mins',
      image: business_management,
      url: '9VlvbpXwLJs',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title:
        'Build a Business That Runs WITHOUT You & Gives You FINANCIAL FREEDOM',
      image: business_management,
      url: 'w8t7_M6Yt1w',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'How To Set Up A Business In 47 Minutes',
      image: business_management,
      url: 'pfQL-M4lkdw',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title:
        'Divergencias - Episodio 015: Lo diferencial de la educación superior.',
      image: education,
      url: 'atzEyFrvag4',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title: 'Educación con enfoque diferencial',
      image: education,
      url: '1VMebO2xjzs',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title: 'Seminario San Antonio: Pedagogías Diferenciales.',
      image: education,
      url: '3wOO_4VZNWE',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title:
        'Tertulias ambientales: Hablemos de enfoque diferencial en la educación ambiental',
      image: education,
      url: 'OHfVEWByp18',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title: 'Unidad I - Pedagogía Diferencial y Atención a la Diversidad',
      image: education,
      url: 'Ih-Yl2k22lQ',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title: 'Showcasing Learnings and Progress: Accelerated Education in LAC',
      image: education,
      url: 'l_0qw9UrSKQ',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title:
        'Transformative pedagogies and technologies in online postgraduate education',
      image: education,
      url: '2Txw4xL0r0o',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title:
        'Divergencias - Episodio 015: Lo diferencial de la educación superior.',
      image: education,
      url: 'atzEyFrvag4',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title: 'Educación con enfoque diferencial',
      image: education,
      url: '1VMebO2xjzs',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title: 'Seminario San Antonio: Pedagogías Diferenciales.',
      image: education,
      url: '3wOO_4VZNWE',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title:
        'Tertulias ambientales: Hablemos de enfoque diferencial en la educación ambiental',
      image: education,
      url: 'OHfVEWByp18',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title: 'Unidad I - Pedagogía Diferencial y Atención a la Diversidad',
      image: education,
      url: 'Ih-Yl2k22lQ',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title: 'Showcasing Learnings and Progress: Accelerated Education in LAC',
      image: education,
      url: 'l_0qw9UrSKQ',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title:
        'Transformative pedagogies and technologies in online postgraduate education',
      image: education,
      url: '2Txw4xL0r0o',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title:
        'Engaging and inclusive teaching methods for upskilling and reskilling of adults',
      image: education,
      url: 'G17bN3Auvlk',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Innovative Teaching Methods',
      image: education,
      url: '_Q2hJcFduok',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Teaching Strategies for online OER digital materials',
      image: education,
      url: '5p8twgVy9PU',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'How to Create Effective Patient Education Materials',
      image: education,
      url: '93FoTEyVfgU',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Gen AI and Open Educational Resources in Teaching and Learning',
      image: education,
      url: 'OZVKM-8OM7Y',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title:
        'Is Education Ready for AI? ¿Está la educación preparada para la IA?',
      image: education,
      url: 'o-pWqZ2e2HY',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title: 'Inteligencia Artificial en la Educación.',
      image: education,
      url: 'fDiK8awDOCU',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title:
        'Revolutionizing Education: Unlocking the Potential of Virtual Classrooms',
      image: education,
      url: 'WHWcJpjwSuA',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    // {
    //   title: 'Out of Our Minds: Learning to Be Creative by Ken Robinson',
    //   image: education,
    //   url: 'N_FZnATgHeQ',
    //   duration: '',
    //   description: '',
    //   language: 'EN',
    //   type: 'youtube',
    // },
    {
      title:
        'Adoptando IA en Educación: ¿Un camino hacia un aprendizaje inclusivo y efectivo?',
      image: education,
      url: 'vU4rtXapTCg',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title: 'Will AI in education help students live fulfilling lives?',
      image: education,
      url: '7Q8vCH5Mgwo',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title:
        'What can schools, higher education and the labour market do right now to tackle climate change?',
      image: education,
      url: 'RPfv84_FG2k',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Webinar: Battling AI bias in the classroom',
      image: education,
      url: 'qHC_LEinBAI',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Cultura Política',
      image: policy,
      url: 'Aag3MimtZTc',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title:
        'Igualdad de género “¿Los líderes de género gobiernan nuestras ciudades?”',
      image: policy,
      url: 'cJ2GyPCexLA',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title:
        'Laboratorio de Políticas de Inclusión: La evidencia puesta al servicio de las personas',
      image: policy,
      url: 'Uadzii-Wzx4',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title:
        'Los determinantes del crecimiento económico: Reflexiones a la luz de la experiencia de la OCDE',
      image: policy,
      url: '3yhfId9i2Tk',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title:
        'Seminario El desarrollo político en América Latina y el Caribe en la última década.',
      image: policy,
      url: 'dJNy0jZF5p4',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title: 'Making Progress on Gender Equity at the IFIs',
      image: policy,
      url: 'KPKpMOuSZr8',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Paths to progress: Race, equity, and democracy',
      image: policy,
      url: 'n_hjmcM4Qrs',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title:
        'Being the Change: Towards Greater Inclusion and Diversity in International Development Evaluation',
      image: policy,
      url: 'kCxMhJYSdPI',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Advancing Equity Through Workforce Development',
      image: policy,
      url: '95bPOtrNyRo',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title:
        'The Dawn Of A New Era In Learning & Development. Where Is This Going?',
      image: policy,
      url: '-pTFb4-HWB8',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title:
        'The Art of Storytelling to Drive Organizational Change with TED Speaker and Author Karen Eber',
      image: policy,
      url: 'A5UANc4CIgA',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'The Secret to Giving Great Feedback, with LeeAnn Renninger',
      image: policy,
      url: 'pYNEZs3-ld8',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title:
        'Be Human, Lead Human, How to Connect People and Performance, with Jennifer Nash',
      image: policy,
      url: 'U2SvglpDlLU',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title:
        'Baby Development In This Modern World By AIIMS Doctor And Ted Talk Speaker',
      image: policy,
      url: 'qoLC4aJLz4A',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title:
        'World On Fire: The Root Causes of Populism, Authoritarianism and The Whole Global Mess',
      image: policy,
      url: 'oDdssTQG6Vc',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title:
        'The psychology behind our political divide, with Keith Payne, PhD',
      image: policy,
      url: '1Ak-3WsLY-M',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Governing in Times of Crisis',
      image: policy,
      url: 'LbjhNlToPzg',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'The politics and philosophy of AI',
      image: policy,
      url: '5Oqbg72xivw',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'What Strategies Work to Advance Women’s Political Power?',
      image: policy,
      url: 'YZpp4nOuJMA',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'The Future of Information Technology Service Management',
      image: information_technology,
      url: 'nTBtEfPavdU',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title:
        "Enhancing Procurement Strategies for Today's Information Technology Challenges",
      image: information_technology,
      url: 'VjmborzKgJE',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title:
        'Information Systems Strategy: How Digital Transformation Drives Business Success',
      image: information_technology,
      url: 'JcFoVvQFqjg',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Building a Successful Small Business Cybersecurity Strategy',
      image: information_technology,
      url: 'CC-qB8cvfNg',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title:
        'The Art of Prioritization: Making Strategic Choices in Technology Investments',
      image: information_technology,
      url: 'dsV-gMbfeKw',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title:
        'From Vision to Action: How to Turn Your Strategic Plan into Tangible Results',
      image: information_technology,
      url: 'cM48mvhTbWQ',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Cybersecurity Mastery: Complete Course in a Single Video',
      image: information_technology,
      url: 's19BxFpoSd0',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'How AI Can Accelerate Cybersecurity',
      image: information_technology,
      url: 'utcYsBKL7e8',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title:
        'How AI makes hackers smarter, and what the cybersecurity industry is doing to fight back',
      image: information_technology,
      url: 'P_Ob_91On7I',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Cybersecurity of Artificial Intelligence',
      image: information_technology,
      url: 'k2oxvubgVh4',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Mastering Software Architecture - A Comprehensive Guide',
      image: information_technology,
      url: 'S7OLzntTtG0',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Artificial Intelligence in Software Engineering',
      image: information_technology,
      url: 'PfPv0lgdW4g',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Qué es Marketing',
      image: marketing,
      url: 'qDBXLQgdgtw',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title: 'PSICOLOGÍA aplicada al MARKETING para VENDER más',
      image: marketing,
      url: 'TKU_7iz-1ps',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title: 'Cómo será el MARKETING DIGITAL en 2024',
      image: marketing,
      url: 'j6SHkkc3JGU',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title: 'Así Cambiará el Marketing Digital este 2024',
      image: marketing,
      url: 'LOuBICx33NQ',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title: '6 Estrategias de Marketing Digital Avanzadas',
      image: marketing,
      url: '0vLRUMsorVc',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title: 'How quantum marketing will change our lives — For good',
      image: marketing,
      url: 'zuokQCPVriw',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title:
        'Brand of the year CMO on Innovation, TED talks and what B2B can learn from B2C - Rebecca Hirst',
      image: marketing,
      url: 'pP_sX9-NRa4',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title:
        'Marketing sostenible y estrategias para desarrollarlo por Alfonso Tamargo',
      image: marketing,
      url: 'KoTgaDPbGJU',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title: 'Fintech Marketing Summer Conference 2024',
      image: marketing,
      url: 'WUgCbH8H1OE',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Digital Marketing in the age of AI',
      image: marketing,
      url: 'y69P7abf364',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Marketing y liderazgo: Claves para el éxito en la era digital',
      image: marketing,
      url: 'QJXGv3Fs7FI',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title: 'Estrategias Innovadoras de Marketing',
      image: marketing,
      url: 'fkysX5zG61M',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title: 'Neurociencia aplicada al marketing',
      image: marketing,
      url: 'dTZPpZ2XKeg',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title: 'The Business of Marketing Ideas',
      image: marketing,
      url: 'wG5IabNAmxI',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Unlocking the Potential of AI in Marketing',
      image: marketing,
      url: 'BtzBxiFaW5I',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    // {
    //   title:
    //     'how I would learn Digital Marketing in 2025 (if I could start over)',
    //   image: marketing,
    //   url: 'fxnVADG7H7w',
    //   duration: '',
    //   description: '',
    //   language: 'EN',
    //   type: 'youtube',
    // },
    {
      title: "Advertising VS. Marketing: What's The Difference?",
      image: marketing,
      url: 'L6xc2IqPnjM',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: 'Account Based Marketing Digital Advertising Best Practices',
      image: marketing,
      url: 'kJe36J6Ji5M',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title: '4 Years Of Marketing Lessons In 112 Minutes',
      image: marketing,
      url: 'PCVMCXF1Tog',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
    {
      title:
        '¡15 factores desencadenantes de marketing psicológico para HACER QUE LA GENTE COMPRE TUS PRODUCTOS!',
      image: marketing,
      url: 'hYZG8qnS2T4',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title: 'Clase Introducción a la filosofía del desarrollo—Felipe Correa.',
      image: ethics,
      url: 'AGuzx90pe_o',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title: 'Ética empresarial y responsabilidad social',
      image: ethics,
      url: 'l9aannLhP3o',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title:
        'Foro Responsabilidad Social Empresarial: Un reto para las compañías.',
      image: ethics,
      url: 'LxsxuTflOXY',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title:
        'Orígenes y evolución de la Responsabilidad Social Empresarial RSE',
      image: ethics,
      url: 'KAI6Py9AK8U',
      duration: '',
      description: '',
      language: 'ES',
      type: 'youtube',
    },
    {
      title: 'What Is Business Ethics?',
      image: ethics,
      url: '87qVqZE_KQI',
      duration: '',
      description: '',
      language: 'EN',
      type: 'youtube',
    },
  ];

  if (query) {
    const filteredData = data.filter((item) =>
      item.title.toLowerCase().includes(query.toLowerCase())
    );
    return filteredData.sort(() => Math.random() - 0.5);
  }
  // Return all data
  return data.sort(() => Math.random() - 0.5);
};
