import React from 'react';
import { useNavigate } from 'react-router-dom';
// Redux
import { useSelector, useDispatch } from 'react-redux';
// Slice
import { setActiveLoginModal } from '../../app/features/userSlice';
// Styles
import './CardSection.css';

import iaSmall from '../../Images/HomePage/Sections/iaSmall.png';
import iaStars from '../../Images/HomePage/Sections/iaStars.png';

const CardSection = ({ title, url, route }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Slice
  const { user_email } = useSelector((state) => state.user);
  const { requestLogin } = useSelector((state) => state.institution);

  const redirect = () => {
    if (requestLogin) {
      if (user_email.length > 0) {
        navigate(`/${route}`);
      } else {
        dispatch(setActiveLoginModal(true));
      }
    } else {
      navigate(`/${route}`);
    }
  };

  return (
    <div className='cardSection' onClick={redirect} role='button'>
      <div
        style={{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 100%), url(${url}) lightgray 0px 0px / 100.755% 98.667% no-repeat`,
        }}
        className='cardSectionStyle'
        onClick={redirect}
      >
        {title !== 'Aquino IA' ? (
          <p className='CardSection__title'>{title}</p>
        ) : (
          <div className='iaCardHome'>
            <img src={iaSmall} alt='ia' />
            <img className='stars' src={iaStars} alt='ia' />
          </div>
        )}
      </div>
    </div>
  );
};

export default CardSection;
