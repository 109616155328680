export const centerTo = (type = '', number = 0) => {
  setTimeout(() => {
    let element = false;
    if (type === 'loading') {
      element = document.getElementById(`aquinoLoading`);
    } else if (type === 'answer') {
      element = document.getElementById(`containerPrompt_${number}`);
    }
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: type === 'loading' ? 'end' : 'start',
      });
    }
  }, '300');
};
