import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// Redux
import { useSelector } from 'react-redux';
// Components
import Modal from 'react-modal';
import Container from '../../Components/Container';
// Images
import image from '../../Images/Help/onlineLibrary.webp';
import PlayButtonImage from '../../Images/play-button.svg';
// Styles
import './HelpPage.css';

const HelpPage = () => {
  const { institutionCode } = useSelector((state) => state.institution);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <main className='help'>
      <Container>
        <div className='helpContainer'>
          <div className='helpContailer__FAQs'>
            <h1>Aquinas Online Library FAQ’s</h1>
            <div className='customServices'>
              <p>
                <b>How to contact us?</b>
              </p>
              {institutionCode !== 'HCAS' && (
                <p>
                  <b>Customer service number:</b> +57 (786) 964-4822
                </p>
              )}
              <p className='conditions'>
                Calls will be taken only within office hours.
              </p>

              <div className='customServicesActions'>
                {institutionCode === 'HCAS' ? (
                  <a
                    href='mailto:library@dp.hcas.edu'
                    className='customServicesbutton hcas'
                  >
                    Contact our librarian
                  </a>
                ) : (
                  <>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='tel:+19548586482'
                      className='customServicesbutton'
                    >
                      CALL
                    </a>
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://wa.me/+19548586482?text=Hi, I am interested in Online Library services.'
                      className='customServicesbutton'
                    >
                      WHATSAPP
                    </a>
                  </>
                )}
              </div>
            </div>
            <details open>
              <summary className='questionFAQs'>
                What is Aquinas Online Library?
              </summary>
              <p className='questionAnswer'>
                Aquinas Online Library is an online library designed to provide
                academic content in different formats.
              </p>
              <div className='questionAnswer containerVideoHelp'>
                <>
                  <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={() => setModalIsOpen(false)}
                    contentLabel='Video Modal'
                    style={{
                      content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        transform: 'translate(-50%, -50%)',
                        maxWidth: '80%',
                        maxHeight: '80%',
                      },
                    }}
                  >
                    <iframe
                      className='videoModal'
                      title='title video'
                      src={
                        'https://institutions-assets.s3.us-east-2.amazonaws.com/videos/Video_Aquino%26Robert_1080HD_CC.mp4'
                      }
                      controls
                      allowFullScreen
                    ></iframe>
                  </Modal>

                  <div className='card-videoHelp'>
                    <div
                      className='thumbnail_image'
                      onClick={() => setModalIsOpen(true)}
                      role='button'
                      tabIndex='0'
                    >
                      <div className='thumbnail_image__play-button'>
                        <img
                          src={PlayButtonImage}
                          alt='play'
                          className='button-svg'
                        />
                      </div>
                      <div className='thumbnailContainer'>
                        <img
                          src={image}
                          alt='thumbnail'
                          className='thumbnail'
                        />
                      </div>
                      <div className='thumbnail_time'>2:25</div>
                    </div>
                  </div>
                </>
                {/* <ReactPlayer
                  url='https://player.vimeo.com/video/857661585?h=7a7fd7c83f?rel=0'
                  controls
                  width='100%'
                  height='180px'
                ></ReactPlayer> */}
                {/* <ReactPlayer
                  url='https://player.vimeo.com/video/857661537?h=101604f111?rel=0'
                  controls
                  width='100%'
                  height='180px'
                ></ReactPlayer> */}
              </div>
            </details>
            <details>
              <summary className='questionFAQs'>
                How can I find a book from the library?
              </summary>
              <p className='questionAnswer'>
                You can use the online catalog and request it. If the item is
                located in another campus library, you can brought it through
                Interlibrary Loan to this section where the form and procedures
                are.
              </p>
            </details>
            <details>
              <summary className='questionFAQs'>
                How can I make a search to find information for my paper?
              </summary>
              <p className='questionAnswer'>
                There are many resources in Aquinas Online Library that you can
                use according to the topic you selected. If you are not familiar
                with your topic it will be good if you start your search using a
                dictionary. Once you get familiar with the terms it will be
                easier to find the most relevant resource to start your search.
              </p>
            </details>
            <details>
              <summary className='questionFAQs'>
                Are there any guides for writing a paper?
              </summary>
              <p className='questionAnswer'>
                You can go to{' '}
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://extras.apa.org/apastyle/basics-7e/?_ga=2.155904504.238527057.1664917899-1131865895.1664917899#/'
                >
                  Academic Writer
                </a>
                . This is a tutorial that provides basic instructions on APA
                Style seventh Edition. Also, you can use Purdue OwL
              </p>
            </details>
            <details>
              <summary className='questionFAQs'>How can I find a book?</summary>
              <p className='questionAnswer'>
                You can find it in the "Books" section, where you can apply
                filters such as year of publication, edition, author, keyword,
                and language (English and Spanish).
              </p>
            </details>
            <details>
              <summary className='questionFAQs'>
                How can I access the e-books from the publishing houses
                partnered with the library?
              </summary>
              <p className='questionAnswer'>
                In the "e-books" section, you'll find a specialized integration
                for these book collections. Here you will find texts
                specifically selected for the programs of your educational
                institution.
              </p>
            </details>
            <details>
              <summary className='questionFAQs'>
                Can I get support for building my bibliography if I’m working on
                a paper or research?
              </summary>
              <p className='questionAnswer'>
                Of course! Our librarian has access to databases and resources
                to support you. You need to contact them via email at
                librarian@aquinasnetwork.com or create a help ticket using the
                help button. Just remember to register the topic you are working
                on and the scope of your research. They will contact you if they
                have any questions and share a series of articles that could
                help you.
              </p>
            </details>
            <details>
              <summary className='questionFAQs'>
                What should I do if I can’t find a specific reference in the
                library?
              </summary>
              <p className='questionAnswer'>
                You can email our librarian at librarian@aquinasnetwork.com or
                create a help ticket using the help button. They will help you
                find it or suggest others that match your search criteria.
              </p>
            </details>
            <details>
              <summary className='questionFAQs'>
                How can I get a citation tool?
              </summary>
              <p className='questionAnswer'>
                Zotero
                <br />
                Mendeley
              </p>
            </details>

            {institutionCode === 'CMVC' && (
              <details>
                <summary className='questionFAQs'>
                  Where do I go if I want to get more tutoring for my classes?
                </summary>
                <p className='questionAnswer'>
                  The tutoring schedule can be located on the main page of the
                  Aquinas Online Library or by clicking{' '}
                  <Link to='/tutoring'>here</Link>. There is also availability
                  to tutoring through{' '}
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://www.tutor.com/'
                  >
                    tutor.com
                  </a>
                  . If a student is requesting additional resources they should
                  visit their student services coordinator at their campus.
                </p>
              </details>
            )}
          </div>
        </div>
      </Container>
    </main>
  );
};

export default HelpPage;
