import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  videos: [],
  page: 1,
  queryLibraryVideos: '',
  queryLibraryVideosSearched: '',
};

const libraryVideosSlice = createSlice({
  name: 'libraryVideos',
  initialState,
  reducers: {
    setVideos: (state, action) => {
      state.videos = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setQueryLibraryVideos: (state, action) => {
      state.queryLibraryVideos = action.payload;
    },
    setQueryLibraryVideosSearched: (state, action) => {
      state.queryLibraryVideosSearched = action.payload;
    },
  },
});

export const {
  setVideos,
  setQueryLibraryVideos,
  setQueryLibraryVideosSearched,
  setPage,
} = libraryVideosSlice.actions;

export default libraryVideosSlice.reducer;
