import React, { useState } from 'react';
// Image
import example_instructions from '../../Images/LibraryPage/example_instructions.png';
// Styles
import './ExampleInstruction.css';

const ExampleInstruction = ({ message }) => {
	const [showMessage, setShowMessage] = useState(false);

	const getExamples = (message) => {
		return (
			<ul className='message'>
				{message?.map((element, i) => {
					return <li key={i}>{element}</li>;
				})}
			</ul>
		);
	};

	return (
		<div
			className='message-container'
			onMouseEnter={() => setShowMessage(true)}
			onMouseLeave={() => setShowMessage(false)}
		>
			<img
				src={example_instructions}
				alt='example instructions for advanced search'
			/>
			{showMessage && getExamples(message)}
		</div>
	);
};

export default ExampleInstruction;
