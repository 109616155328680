import React from 'react';
// Services
import { sendUserSearchInformation } from '../../services/sendUserSearchInformation';
// Redux
import { useSelector } from 'react-redux';
// Styles
import './CardInterestingLinksHome.css';

const CardInterestingLinksHome = ({
  title,
  description,
  url,
  image_url,
  is_default_image = true,
  type,
  resource_origin,
}) => {
  const { user_email } = useSelector((state) => state.user);
  const { institutionCode } = useSelector((state) => state.institution);

  const handleClicCard = () => {
    sendUserSearchInformation(
      title,
      resource_origin,
      url,
      type,
      user_email,
      institutionCode
    );
    window.open(url, '_blank');
  };

  if (url) {
    return (
      <div
        className='cardResource'
        onClick={handleClicCard}
        role='button'
        tabIndex='0'
      >
        <img
          className={
            is_default_image
              ? type === 'resource'
                ? 'cardResource__image resource'
                : 'cardResource__image'
              : type === 'resource'
              ? 'cardResource__image default__image resource'
              : 'cardResource__image default__image'
          }
          src={image_url}
          alt=' resource'
        />
        <div className='cardResourceContainer'>
          <p className='cardResource__title'>{title}</p>
          {description && <p className='cardResource__text'>{description}</p>}
        </div>
      </div>
    );
  }
};

export default CardInterestingLinksHome;
